import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const query = graphql`
	query HeaderMenuQuery {
		wpMenu(name: {eq: "Main Menu"}) {
			menuItems {
				nodes {
					url
					label
				}
			}
		}
	}
`;

const Menu = ({ id }) => {
	const [currentURL, setCurrentURL] = useState('');

	useEffect(() => {
		setCurrentURL(window.location.pathname);
	}, [currentURL])
	const data = useStaticQuery(query);

	const menuItems = data.wpMenu.menuItems.nodes;
	return (
		<nav id={id}>
				<ul className="nav-links">
					<li className={`nav-link-item ${currentURL === '/' ? 'menuItemSelected' : ''}`}>
						<AniLink
							className="nav-link-text"
							paintDrip hex="#EE82BF"
							to="/"
							duration={0.8}
						>
							Home
						</AniLink>
					</li>
					{menuItems.map(item => {
						return (
							<li
								key={item.url}
								className={`nav-link-item ${currentURL === item.url ? 'menuItemSelected' : ''}`}
							>
								<AniLink
									to={item.url}
									className="nav-link-text"
									cover bg="#EE82BF"
									direction="right"
									duration={0.8}
								>
									{item.label}
								</AniLink>
							</li>
						)
					})}
				</ul>
			</nav>
	);
};

export default Menu;