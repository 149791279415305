import React, { useEffect } from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Menu from './menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql} from 'gatsby';

import CookieConsent from "react-cookie-consent";

import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

const contact_query = graphql`
query ContactQuery {
	wpComponent(databaseId: {eq: 1750}) {
			title
			content
		}
}
`;

const scrollUp = (event) => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
	event.target.classList.remove('show');
}

const Footer = () => {
	const data = useStaticQuery(contact_query);

	const contact = data.wpComponent;

	useEffect(() => {
		if (document.getElementById('toTop') !== undefined){
			window.addEventListener('scroll', () => {
				if (window.scrollY > 100){
					document.getElementById('toTop').classList.add('show');
				} else {
					document.getElementById('toTop').classList.remove('show');
				}
			});
			if (window.scrollY > 100 || window.pageYOffset > 100){
				document.getElementById('toTop').classList.add('show');
			} else {
				document.getElementById('toTop').classList.remove('show');
			}
		}
	}, [])

	return (
		<>
			<footer>
				<CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
				<section className="content">
					<div id="footerCompany">
						<div id="footerLogoContainer">
							<StaticImage src="../images/logo_new_footer2.png" alt="logo" id="footerLogo"/>
						</div>
						<div id="footerCopy">
							<p><strong>&copy; 2021 m1nd-set</strong></p>
							<p>All rights reserved.</p>
						</div>
						<div>
							<AniLink
								to="/privacy-policy-newsletters"
								cover bg="#ec008c"
								direction="right"
								duration={0.8}
							>
								Privacy Policy - Newsletters
							</AniLink>
							<br />
							<AniLink
								to="/privacy-policy-surveys"
								cover bg="#ec008c"
								direction="right"
								duration={0.8}
							>
								Privacy policy - Surveys
							</AniLink>
						</div>
					</div>
					<Menu id="footerMenu"/>
					<div id="footerContact">
						<h2>{contact.title}</h2>
						<div dangerouslySetInnerHTML={{ __html: contact.content }}/>
					</div>
				</section>
				<p id="deLine">
					Web design and programming: <a href="https://dataexpert.hu" target="_blank" rel="noreferrer">DataExpert Services Ltd.</a>
				</p>
				<div
					id="toTop"
					role="button"
					onClick={scrollUp}
					onKeyDown={scrollUp}
					tabIndex={0}
				>
					{/* <i className="fas fa-chevron-up"></i> */}
					{/* <FontAwesomeIcon icon="fa-chevron-up"/> */}
					<FontAwesomeIcon icon={faChevronUp} size="1x" />
				</div>
			</footer>
		</>
	)
};

export default Footer;